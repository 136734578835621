import { SVGProps } from 'react';
export const EyeClosedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="svg-icon"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      fill="none"
      stroke={props.fill}
      strokeLinecap="round"
      strokeWidth={1.5}
    >
      <path
        strokeLinejoin="round"
        d="M10.73 5.073A10.96 10.96 0 0 1 12 5c4.664 0 8.4 2.903 10 7a11.595 11.595 0 0 1-1.555 2.788M6.52 6.519C4.48 7.764 2.9 9.693 2 12c1.6 4.097 5.336 7 10 7a10.44 10.44 0 0 0 5.48-1.52m-7.6-7.6a3 3 0 1 0 4.243 4.243"
      />
      <path d="m4 4 16 16" />
    </g>
  </svg>
);
