import { isBefore } from 'date-fns';

export const timestampTokenValidation = (timestamp: any) => {
  const currentDateTime = new Date();
  const timestampDateTime = new Date(timestamp * 1000); // Multiplica por 1000 para convertir el timestamp a milisegundos
  const isExpired = isBefore(timestampDateTime, currentDateTime);
  return isExpired;
};

export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Añade ceros a la izquierda si es necesario
  const day = date.getDate().toString().padStart(2, '0'); // Añade ceros a la izquierda si es necesario
  const currentDate = `${year}-${month}-${day}`;
  return currentDate;
};
