import { FaSpinner } from 'react-icons/fa';

interface props {
  size?: number;
}
export const LoadingIcon = ({ size = 2 }: props) => {
  const iconSize = size * 16;
  return (
    <span className="flex justify-center items-center h-auto text-blue-500">
      <FaSpinner
        size={iconSize}
        className="loader"
      />
    </span>
  );
};
