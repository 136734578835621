import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { MainProvider } from './providers';
import { MainRouter } from './router/MainRouter';

// Crea una instancia de QueryClient
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MainProvider>
        <MainRouter />
      </MainProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
