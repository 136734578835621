'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { TFormFilterProfesionalPatirentValues } from '../../../../../../types';
import { IComboboxBasic } from '../../../../../../interfaces';

import { ComboBox, TextField } from '../../../../../../components';

const schema = z.object({
  profesionalName: z.string().nonempty('Profesional requerido'),
  // email: z.string().nonempty('Email requerido').email('Formato del correo no es valido'),
  documentPatient: z.string(),
  firstNamePatient: z.string(),
  secondNamePatient: z.string(),
  firstSurnamePatient: z.string(),
  secondSurnamePatient: z.string(),
});

interface props {
  listProfesionals: IComboboxBasic[];
  filterData: any;
}
export const FilterByProfesionalPatient = ({ listProfesionals, filterData }: props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormFilterProfesionalPatirentValues>({
    defaultValues: {
      profesionalName: '',
      documentPatient: '',
      firstNamePatient: '',
      secondNamePatient: '',
      firstSurnamePatient: '',
      secondSurnamePatient: '',
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: any) => {
    filterData(data);
  };

  return (
    <form
      className="flex flex-col"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col">
        <ComboBox
          id={'profesionalName'}
          label={'Profesional de la salud'}
          register={register}
          options={listProfesionals}
          errors={errors}
        />

        <div className="p-2">
          <div className="flex flex-col items-center justify-between gap-1 lg:flex-row">
            <TextField
              type={'text'}
              id={'documentPatient'}
              label={'Documento ID'}
              register={register}
              errors={errors}
            />
            <TextField
              type={'text'}
              id={'firstNamePatient'}
              label={'Primer Nombre'}
              register={register}
              errors={errors}
            />
            <TextField
              type={'text'}
              id={'secondNamePatient'}
              label={'Segundo Nombre'}
              register={register}
              errors={errors}
            />
            <TextField
              type={'text'}
              id={'firstSurnamePatient'}
              label={'Primer Apellido'}
              register={register}
              errors={errors}
            />
            <TextField
              type={'text'}
              id={'secondSurnamePatient'}
              label={'Segundo Apellido'}
              register={register}
              errors={errors}
            />
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="rounded-lg bg-bidi-darkblue py-2 text-slate-50 hover:bg-bidi-lightblue"
      >
        Buscar Paciente
      </button>
    </form>
  );
};
