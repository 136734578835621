import { IPropsSidebarMenuItem } from '../../interfaces';
import { Link } from 'react-router-dom';

interface Props {
  item: IPropsSidebarMenuItem;
  activeItem: string;
  onClick: (title: string) => void;
}

export const SidebarMenuItem = ({ item, activeItem, onClick }: Props): JSX.Element => {
  const { title, subtitle, path, icon } = item;

  const onHandleClick = () => {
    onClick(title);
  };

  return (
    <div onClick={onHandleClick}>
      <Link
        to={path}
        className={`ml-2 w-[220px] inline-flex l items-center space-x-2 rounded-md border-b-2 px-2 hover:bg-gray-200  ${
          activeItem === title ? 'bg-bidiBlue-100' : ''
        }`}
      >
        <span className="text-sm text-bidiBlue-700">{icon}</span>
        <div className="flex flex-col">
          <span className="text-bidiBlue-700">{title}</span>
          <span className="text-xs text-gray-400">{subtitle}</span>
        </div>
      </Link>
    </div>
  );
};
