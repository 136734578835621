import { MainAnimation } from '../../../components';

import { AppLayout } from '../../layouts';
// import { ListProfesionals } from './components/ListProfesionals';

export function HomePage() {
  return (
    <AppLayout>
      <div className="mt-14 flex h-5/6 items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center">
          <MainAnimation title={'.:: BidI ::.'} />

          {/* <ListProfesionals /> */}
        </div>
      </div>
    </AppLayout>
  );
}
