import { IPatientInfoBasic } from '../../../../../..//interfaces';
import { CardPatient } from './CardPatient';

interface props {
  items: IPatientInfoBasic[];
  setSelectedPatient: any;
  setOpenModal: any;
  setOpenConfirmEmail: any;
}
export const Cards = ({ items, setSelectedPatient, setOpenModal, setOpenConfirmEmail }: props) => {
  return (
    <div className="grid grid-cols-1 gap-4 p-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
      {items.map((item) => {
        return (
          <div
            key={item.id}
            className="animate__animated animate__fadeInLeft flex justify-center opacity-100 transition-opacity duration-500"
          >
            <CardPatient
              patient={item}
              setSelectedPatient={setSelectedPatient}
              setOpenModal={setOpenModal}
              setOpenConfirmEmail={setOpenConfirmEmail}
            />
          </div>
        );
      })}
    </div>
  );
};
