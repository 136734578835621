interface props {
  icon?: JSX.Element;
  text: string;
}
export const BreadCrumb = ({ icon, text }: props) => {
  return (
    <div className="flex h-14 w-full items-center justify-between rounded-sm bg-bidi-darkblue p-5 text-slate-50">
      <span>{icon}</span>
      <span className="hidden md:block">{text}</span>
      <span />
    </div>
  );
};
