import { Logo, UserDropDown } from '..';

const LogoCompany = process.env.PUBLIC_URL + '/images/company.png';
const LogoProduct = process.env.PUBLIC_URL + '/images/product.png';

import { useAuth } from '../../auth/hooks/useAuth';

export const Navbar = () => {
  const { getUser } = useAuth();
  const { name } = getUser();
  return (
    <div className="fixed left-0 right-0 top-0 z-50 flex h-16 w-full items-center justify-between bg-[#f8fafc] bg-opacity-100 text-bidi-lightblue">
      <div className="flex h-16 w-14 items-center justify-start bg-slate-50 pl-3 md:w-64 md:justify-center ">
        <Logo
          logo={LogoProduct}
          alt={'bidi logo'}
          width={85}
          height={20}
          style="hidden md:block"
        />
      </div>
      <div className="header-right flex h-16 items-center justify-between border-b-2">
        <Logo
          logo={LogoCompany}
          alt={'bidi interconsultas'}
          width={180}
          height={40}
          style="hidden md:block"
        />
        <span></span>
        <div className="mr-2">
          <UserDropDown
            name={name || ''}
            imgAvatar={'/images/avatar.png'}
          />
        </div>
      </div>
    </div>
  );
};
