import Lottie from 'lottie-react';
import animationData from './67736-big-data-analysis.json';

interface props {
  title?: string;
}
export const MainAnimation = ({ title }: props) => {
  return (
    <>
      {title && <div className="animate-pulse items-center text-5xl text-bidiBlue-1000 transition duration-500">{title}</div>}
      <Lottie animationData={animationData} />
    </>
  );
};
