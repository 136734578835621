import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OptionsObject, enqueueSnackbar } from 'notistack';
import Cookies from 'js-cookie';

import { Overlay, Logo } from '../../../components';
import { useAuth } from '../../hooks/useAuth';
import { IAuthState, ICredentialsAuth } from '../../../interfaces';
import { authApi } from '../../../api';
import { LoginForm } from './components/LoginForm';
import { deleteCookieUser } from '../../../helpers';

export default function LoginPage() {
  const LogoCompany = process.env.PUBLIC_URL + '/images/company.png';

  const navigate = useNavigate();
  const { setLogin } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    deleteCookieUser();
  }, []);

  async function onSubmit(data: ICredentialsAuth) {
    try {
      setIsLoading(true);
      const response = await authApi.login({
        username: data.username,
        password: data.password,
      });
      const user: IAuthState = {
        isAuthenticated: true,
        user: {
          token: response?.token || '',
          name: response?.name || '',
          role: response?.role || '',
        },
      };
      // const maxAgeCookie = 2 * 60 * 60 * 1000;
      // const maxAge = 5 * 60 * 1000;
      Cookies.set('_name', response?.name || '');
      Cookies.set('_role', response?.role || '');
      Cookies.set('_token', response?.token || '');
      setIsLoading(false);
      setLogin(user);
      navigate('/', {
        replace: true,
      });
    } catch (error: any) {
      setIsLoading(false);
      if (error.response.status === 401) {
        const { message } = error.response.data;
        const options: OptionsObject = {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          style: { marginTop: '50px' },
        };
        enqueueSnackbar(message, options);
        return;
      } else {
        console.log(error.response);
        const options: OptionsObject = {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          style: { marginTop: '50px' },
        };
        enqueueSnackbar('😕 Algo salió mal, por favor solicitar soporte técnico!', options);
        return;
      }
    }
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center rounded-lg border border-gray-300 p-6 shadow-md">
        <h1 className="mb-1 text-4xl font-bold text-bidi-darkblue">Bienvenido</h1>
        <h2 className="mb-4 text-xs text-gray-400">Por favor, inicia sesión para continuar</h2>
        <div className="mb-4">
          <Logo
            logo={LogoCompany}
            alt={'bidi interconsultas'}
            width={270}
            height={66}
            style=""
          />
        </div>
        <LoginForm onSubmit={onSubmit} />
      </div>
      <Overlay
        isLoading={isLoading}
        msg="Iniciando sesión....."
      />
    </div>
  );
}
