interface props {
  id: string;
  label: string;
  defaultValue?: any;
  register: any;
  options: any[];
  errors: any;
}
export const ComboBox = ({ id, label, defaultValue = '', register, options, errors }: props) => {
  return (
    <div className="p-2">
      <label className="mb-2 block text-sm font-bold text-bidiBlue-1000">{label}</label>
      <select
        id={id}
        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
        {...register(id)}
        defaultValue={defaultValue}
      >
        <option
          value=""
          disabled
        >
          Selecciona una opción
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.displayValue}
          </option>
        ))}
      </select>
      {errors && <p className="text-sm italic text-red-500">{errors[id]?.message}</p>}
    </div>
  );
};
