import { Navigate, Route, Routes } from 'react-router-dom';
import { ClinicalRecordSendEmailTodayPage, HomePage, Page404 } from '../pages';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<HomePage />}
      />
      <Route
        path="/clinic-record-send-today"
        element={<ClinicalRecordSendEmailTodayPage />}
      />
      <Route
        path="/not-found"
        element={<Page404 />}
      />
      <Route
        path="*"
        element={<Navigate to="/not-found" />}
      />

      {/* <Route path="hero/:id" element={<HomePage />} /> */}

      {/* <Route
        path="/*"
        element={<HomePage />}
      /> */}
    </Routes>
  );
};

export default AppRoutes;
