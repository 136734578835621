import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { IAuthState } from '../../interfaces/auth';

const _name = Cookies.get('_name');
const _role = Cookies.get('_role');
const _token = Cookies.get('_token');

let initialState: IAuthState = {
  isAuthenticated: false,
};

if (_token && _role && _name) {
  initialState = {
    isAuthenticated: true,
    user: {
      name: _name,
      role: _role,
      token: _token,
    },
  };
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<IAuthState>) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = undefined;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
