import { MdOutlineAttachEmail } from 'react-icons/md';
import { IPatientInfoBasic } from '../../../../../../interfaces';

interface props {
  patient: IPatientInfoBasic;
  profesional: string | undefined;
  date: string;
  onClick: any;
}
export const SendEmailRequest = ({ patient, profesional, date, onClick }: props) => {
  const fullName = `${patient.identifiType} ${patient.identifi} - ${patient.firstName ?? ''} ${patient.secondName ?? ''} ${
    patient.firstSurname ?? ''
  } ${patient.secondSurname ?? ''}`;
  return (
    <div className="w-full">
      <div className="mb-5 p-2 text-justify">
        <span className="lg:txt-lg text-sm text-bidiBlue-1000 sm:text-xs md:text-sm">
          ¿Esta seguro que desea enviar la historia clinica de <span className="font-bold">{fullName.trim()}</span>
          <span>
            , al correo: <span className="font-bold text-zinc-900">{patient.emailClinicRecord}</span>
          </span>
          , correspondiente a la atencion del profesional: <span className="font-bold">{profesional?.trim()}</span>, del dia:{' '}
          <span className="font-bold">{date}</span>?
        </span>
      </div>

      <div className="flex w-full justify-end rounded-b-lg border-gray-200 bg-bidiBlue-500 shadow">
        <button
          type="button"
          className="hover:bg-hsl-208-60-39-40 inline-flex items-center rounded-lg rounded-tr-none bg-bidiBlue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-bidiBlue-400 "
          onClick={onClick}
        >
          <MdOutlineAttachEmail
            size={20}
            className="mr-2"
          />
          Enviar
        </button>
      </div>
    </div>
  );
};
