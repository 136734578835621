interface props {
  open: boolean;
  icon?: JSX.Element;
  title: string;
  subtitle?: string;
  closeModal: any;
  children?: JSX.Element;
}
export const Modal = ({ open, icon, title, subtitle, closeModal, children }: props) => {
  return (
    <>
      {open && (
        <div
          className="main-modal fixed inset-0 z-50 flex w-full items-center justify-center overflow-hidden transition-opacity duration-500"
          style={{ background: 'rgba(0,0,0,.7)' }}
        >
          <div className="animate__animated animate__fadeInUp modal-container md:max-w-11/12 z-50 mx-auto w-6/12 overflow-y-auto rounded-xl border  bg-white shadow-lg">
            <div className="modal-content text-left">
              <div className="mb-3 flex items-center justify-between bg-bidiBlue-1000 p-3 text-slate-50">
                <div className="flex flex-row items-center justify-between bg-bidiBlue-1000">
                  {icon && <div className="mr-4  sm:-scale-105 lg:-scale-150">{icon}</div>}
                  <div>
                    <p className="lg:txt-2x md:text-md text-sm font-bold sm:text-sm">{title}</p>
                    {subtitle && <p className="lg:txt-xl text-sm font-bold sm:text-xs md:text-sm">{subtitle}</p>}
                  </div>
                </div>
                <div
                  className="modal-close z-50 cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault;
                    closeModal(false);
                  }}
                >
                  <svg
                    className="transform fill-current transition-all duration-300 hover:-scale-150"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                  </svg>
                </div>
              </div>

              {children && <>{children}</>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
