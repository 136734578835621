import { Route, Routes } from 'react-router-dom';

import AppRoutes from '../app/routes/AppRoutes';

import LoginPage from '../auth/pages/login';

export const MainRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={<LoginPage />}
        />
        <Route
          path="/*"
          element={<AppRoutes />}
        />
      </Routes>
    </>
  );
};
