import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserIcon, LockIcon, EyeClosedIcon, EyeIcon, Tooltip } from '../../../../components';

interface props {
  onSubmit: any;
}
export const LoginForm = ({ onSubmit }: props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <UserIcon
            fill={'#9E9E9E'}
            width={24}
            height={24}
          />
        </div>
        <input
          type="text"
          {...register('username', { required: true, minLength: 4, maxLength: 15 })}
          placeholder="Usuario"
          className={`block w-full rounded-lg border-2 p-2 pl-10 pr-10 text-sm ${
            errors.username ? 'border-red-500' : 'border-gray-300'
          }`}
        />
      </div>
      {errors.username && (
        <p className="text-xs text-red-500">
          {errors.username.type === 'required'
            ? 'Usuario requerido'
            : errors.username.type === 'minLength'
            ? 'Debe tener al menos 4 caracteres'
            : errors.username.type === 'maxLength'
            ? 'Debe tener máximo 15 caracteres'
            : ''}
        </p>
      )}

      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <LockIcon
            fill={'#9E9E9E'}
            width={28}
            height={28}
          />
        </div>
        <input
          type={showPassword ? 'text' : 'password'}
          {...register('password', { required: true, minLength: 4, maxLength: 15 })}
          placeholder="Contraseña"
          className={`block w-full rounded-lg border-2 p-2 pl-10 pr-10 text-sm ${
            errors.password ? 'border-red-500' : 'border-gray-300'
          }`}
        />

        <div className="group relative flex">
          <span
            className="absolute inset-y-0 right-0 -mt-10 ml-2 flex cursor-pointer items-center pr-3 text-gray-500"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <EyeClosedIcon
                fill={'#9E9E9E'}
                width={28}
                height={28}
              />
            ) : (
              <EyeIcon
                fill={'#9E9E9E'}
                width={28}
                height={28}
              />
            )}
          </span>
          <Tooltip
            conditional={showPassword}
            text={'Ocultar contraseña'}
            textF={'Ver contraseña'}
          />
        </div>
      </div>
      {errors.password && (
        <p className="text-xs text-red-500">
          {errors.password.type === 'required'
            ? 'Contraseña requerida'
            : errors.password.type === 'minLength'
            ? 'Debe tener al menos 4 caracteres'
            : errors.password.type === 'maxLength'
            ? 'Debe tener máximo 15 caracteres'
            : ''}
        </p>
      )}

      <button
        type="submit"
        className="rounded-lg bg-bidi-darkblue py-2 text-white hover:bg-bidi-lightblue"
      >
        Iniciar Sesión
      </button>
    </form>
  );
};
