import axios from 'axios';
import { ICredentialsAuth } from '../../interfaces';
import { IUser } from '../../interfaces';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const authApi = axios.create({
  baseURL: `${apiUrl}/auth`,
  headers: {},
  withCredentials: true,
});

export const login = async (data: ICredentialsAuth): Promise<IUser | undefined> => {
  const response = await authApi.post<IUser>('/login', data);
  return response.data;
};

export default {
  login,
};
