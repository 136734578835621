import { Navbar, Sidebar } from '../../components';

interface props {
  children: React.ReactNode;
}
export const AppLayout = ({ children }: props) => {
  return (
    <>
      <main>
        <div className="flex min-h-screen flex-auto flex-shrink-0 flex-col bg-bidi-bg text-black antialiased">
          <Navbar />
          <Sidebar />
          <div className="mb-10 ml-14 mt-14 h-full p-2 md:ml-64">{children}</div>
        </div>
      </main>
    </>
  );
};
