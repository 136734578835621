import React from 'react';

interface EmailFieldProps {
  id: string;
  label: string;
  placeholder?: string;
  register: any;
  errors: any;
}

export const EmailField: React.FC<EmailFieldProps> = ({ id, label, placeholder, register, errors }) => {
  const sytle =
    'form-control wfull px-3 py-1.5 text-gray-700 rounded border border-solid border-gray-300 focus:borde-blue-500 focus:outline-none';

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <label className="mb-2 block text-sm font-bold text-bidiBlue-1000">{label}</label>
        <input
          type="email"
          placeholder={placeholder}
          className={sytle}
          {...register(id)}
        />
        {errors && <p className="text-sm italic text-red-500">{errors[id]?.message}</p>}
      </div>
    </div>
  );
};

export default EmailField;
