import { SVGProps } from 'react';
export const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="svg-icon"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="none"
      stroke={props.fill}
      d="M4.5 6.5v-3a3 3 0 0 1 6 0v3m-8 0h10a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1Z"
    />
  </svg>
);
