interface Props {
  logo: string;
  alt: string;
  width: number;
  height: number;
  style: string;
}

export const Logo = ({ logo, alt, width, height, style }: Props): JSX.Element => {
  return (
    <img
      src={logo}
      alt={alt}
      width={width}
      height={height}
      className={style}
    />
  );
};
