import { MdOutlineAttachEmail } from 'react-icons/md';

import { IPatientInfoBasic } from '../../../../../../interfaces';
import { StatusSendEmail } from './StatusSendEmail';
import { Logo } from '../../../../../../components';
import { OptionsObject, enqueueSnackbar } from 'notistack';

interface props {
  patient: IPatientInfoBasic;
  setSelectedPatient: any;
  setOpenModal: any;
  setOpenConfirmEmail: any;
}
export const CardPatient = ({ patient, setSelectedPatient, setOpenModal, setOpenConfirmEmail }: props) => {
  const {
    id,
    identifiType,
    identifi,
    firstName,
    secondName,
    firstSurname,
    secondSurname,
    birth,
    biologicalSex,
    homePhone,
    phone,
    statusSendEmailClinicRecord,
    email,
    emailClinicRecord,
    emailVerify,
    updatedEmailAnnar,
  } = patient;
  const imgMaculino = process.env.PUBLIC_URL + '/images/avatar-m.png';
  const imgFemenino = process.env.PUBLIC_URL + '/images/avatar-f.png';
  const textColorClass =
    emailVerify && emailVerify?.length > 0
      ? 'text-green-700'
      : emailClinicRecord && emailClinicRecord?.length > 0
      ? 'text-amber-700'
      : 'text-red-700';
  const textEmailVerify =
    emailVerify && emailVerify?.length > 0 ? '✅' : emailClinicRecord && emailClinicRecord?.length > 0 ? '⚠️' : '❓';
  const isVerify = emailVerify && emailVerify?.length > 0 ? true : false;

  const handleClickSendMail = () => {
    setSelectedPatient(patient);
    if (emailClinicRecord?.length === 0 || emailClinicRecord?.length === undefined) {
      setOpenConfirmEmail(true);
    } else {
      if (statusSendEmailClinicRecord?.length === 0) {
        setOpenModal(true);
      } else {
        const options: OptionsObject = {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        };
        setTimeout(() => {
          enqueueSnackbar(
            'Atención: Para el día de hoy y el profesional seleccionado, el paciente ya tiene una solicitud de envió de historia clínica.',
            options
          );
        }, 500);
      }
    }
  };

  return (
    <div className="h-[250px] relative mt-4 w-full rounded-lg border border-gray-200 bg-white shadow">
      {statusSendEmailClinicRecord && <StatusSendEmail status={statusSendEmailClinicRecord} />}
      <div className="mt-3 h-[200px] p-6">
        <div className="flex flex-row items-center justify-between ">
          <div>
            <p className="text-lg font-bold text-bidiBlue-1000">
              {identifiType} {identifi}
              {/* CC 12345678 */}
            </p>
            <p className="text-lg text-bidiBlue-1000">
              {firstName} {secondName}
              {/* JUAN */}
            </p>
            <p className="text-lg text-bidiBlue-1000">
              {firstSurname} {secondSurname}
              {/* PEREZ */}
            </p>
            {isVerify && (
              <p className={`text-xs ${textColorClass}`}>
                Correo: {emailVerify?.toLowerCase()} {textEmailVerify}
              </p>
            )}

            {!isVerify && (
              <p className={`text-xs ${textColorClass}`}>
                Correo: {emailClinicRecord && emailClinicRecord !== '' ? emailClinicRecord.toLowerCase() : email?.toLowerCase()}{' '}
                {textEmailVerify}
              </p>
            )}

            {updatedEmailAnnar && <p className="text-xs text-purple-950">Correo usado 🔬: {updatedEmailAnnar?.toLowerCase()}</p>}
            {/* <p className="text-sm text-blue-500">Email: juand@gmail.com</p> */}
            {phone && <p className="text-xs text-blue-500">Celular: {phone}</p>}
            {/* {phone && <p className="text-xs text-blue-500">Celular: 3000000000</p>} */}
            {homePhone && <p className="text-xs text-blue-500">Tel. Fijo: {homePhone}</p>}
          </div>
          <div>
            <Logo
              logo={biologicalSex === 'M' ? imgMaculino : imgFemenino}
              alt={'avatar'}
              width={64}
              height={64}
              style="hidden md:block"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end rounded-b-lg border-gray-200 bg-bidiBlue-500 shadow">
        <button
          type="button"
          className="hover:bg-hsl-208-60-39-40 inline-flex items-center rounded-lg rounded-tr-none bg-bidiBlue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-bidiBlue-400  focus:ring-blue-800"
          onClick={handleClickSendMail}
        >
          <MdOutlineAttachEmail
            size={20}
            className="mr-2"
          />
          Enviar
        </button>
      </div>
    </div>
  );
};
