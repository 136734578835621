import { useQuery } from 'react-query';
import { profesionalApi } from '../../api';

export const useProfesionalsListSchedule = () => {
  const query = useQuery(['auth'], profesionalApi.getlistProfesionalsSchedule, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60, // UNA HORA
    // initialData: [], // Usa la data como tipo fresh
    // placeholderData: [
    //   {
    //     value: 'SELECCIONAR PROFESIONAL',
    //     displayValue: 'SELECCIONAR PROFESIONAL',
    //   },
    // ],
  });
  return query;
};
