import { NoSymbolIcon, VerifyIcon, WarningIcon } from '../../../../../../components';

interface props {
  status: string;
}
export const StatusSendEmail = ({ status }: props) => {
  const variants = {
    pendingToValidate: ' bg-red-500',
    pendingToSend: 'bg-yellow-400',
    sent: 'bg-green-500',
  };

  const bgStatus =
    status === 'pendingToValidate'
      ? variants.pendingToValidate
      : status === 'pendingToSend'
      ? variants.pendingToSend
      : status === 'sent'
      ? variants.sent
      : '';

  return (
    <div className={`absolute -top-6 left-3 flex items-center rounded-full ${bgStatus} px-4 py-4 text-white shadow-xl`}>
      {status === 'pendingToValidate' ? (
        <NoSymbolIcon />
      ) : status === 'pendingToSend' ? (
        <WarningIcon />
      ) : status === 'sent' ? (
        <VerifyIcon />
      ) : undefined}
    </div>
  );
};
