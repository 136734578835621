import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoSignOut } from 'react-icons/go';

import { capitalizeTrim } from '../../helpers';
import { useAuth } from '../../auth/hooks/useAuth';

type PropsSignOutButton = {
  name: string;
};
export const SignOutButton = (props: PropsSignOutButton) => {
  const { name } = props;
  const navigate = useNavigate();
  const { setLogout } = useAuth();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLogout();
    navigate('/login', {
      replace: true,
    });
  };

  return (
    <div className="text-sm text-bidiBlue-1000">
      <div>{capitalizeTrim(name, 20)}</div>

      <button
        className="flex w-full items-center justify-center gap-1 rounded-md bg-bidi-lightblue px-2 py-2 text-sm font-medium text-slate-200 hover:bg-bidi-darkblue"
        onClick={handleClick}
      >
        <GoSignOut />
        Salir
      </button>
    </div>
  );
};
