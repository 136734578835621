import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'animate.css';

import './index.css';

import App from './App';

import { MainProvider } from './providers';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <MainProvider>
      <App />
    </MainProvider>
  </BrowserRouter>
);
