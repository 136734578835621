import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../../store/slices';
import { RootState } from '../../store';
import { IAuthState } from '../../interfaces';
import { deleteCookieUser, timestampTokenValidation } from '../../helpers';

export const useAuth = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

  const setLogin = (auth: IAuthState) => {
    dispatch(login(auth));
  };

  const setLogout = () => {
    dispatch(logout());
    deleteCookieUser();
  };

  const getToken = () => {
    return user?.token;
  };

  const getUser = () => {
    return {
      isAuthenticated,
      name: user?.name,
      role: user?.role,
    };
  };

  const getUserId = () => {
    const token = getToken();
    if (!isAuthenticated || !token) return null;
    const decode: any = jwt_decode(token);
    const userId = decode.data;
    if (!userId) return null;
    return userId;
  };

  const getIsAuthenticated = () => {
    const token = getToken();
    if (!isAuthenticated || !token) return false;
    const decode: any = jwt_decode(token);
    const isExpired = timestampTokenValidation(decode.exp);
    if (isExpired) return false;
    return true;
  };

  return { setLogin, setLogout, getToken, getUser, getUserId, getIsAuthenticated };
};
