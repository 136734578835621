interface props {
  isLoading: boolean;
  msg?: string;
}
export const Overlay = ({ isLoading, msg }: props) => {
  return (
    <>
      {isLoading && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="rounded-lg bg-white p-4 shadow-lg">
            <h2 className="mb-2 text-lg font-semibold">{msg ? msg : 'Cargando...'}</h2>
            {/* <p>Espere un momento mientras se carga el contenido.</p> */}
          </div>
        </div>
      )}
    </>
  );
};
