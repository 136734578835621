import axios from 'axios';

import { IComboboxBasic } from '../../interfaces';
import { sleep } from '../../helpers';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const profesionalApi = axios.create({
  baseURL: `${apiUrl}/profesionals`,
  headers: {},
});

export const getlistProfesionalsSchedule = async (): Promise<IComboboxBasic[] | undefined> => {
  try {
    await sleep(2);
    const { data } = await profesionalApi.get<IComboboxBasic[]>('/listProfesionalsSchedule');
    return data;
  } catch (error) {
    console.log(error);
  }
};

export default {
  getlistProfesionalsSchedule,
};
