import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { LoadingIcon, Modal } from '../../../../../../components';
import { patientApi } from '../../../../../../api';
import { getCurrentDate } from '../../../../../../helpers';

import { FilterByProfesionalPatient } from './FilterByProfesionalPatient';

import { IComboboxBasic, IPatientInfoBasic } from '../../../../../../interfaces';
import { TFormFilterProfesionalPatirentValues } from '../../../../../../types';
import { Cards } from './Cards';
import { MdOutlineAttachEmail } from 'react-icons/md';
import { SendEmailRequest } from './SendEmailRequest';
import { OptionsObject, enqueueSnackbar } from 'notistack';
import { ConfirmEmail } from './ConfirmEmail';
import { useAuth } from '../../../../../../auth/hooks/useAuth';

interface Props {
  listProfesionals: IComboboxBasic[];
}
export const MainSection = ({ listProfesionals }: Props) => {
  const { getUserId } = useAuth();
  const [dataFilter, setDataFilter] = useState<TFormFilterProfesionalPatirentValues | undefined>(undefined);
  const [resultsFilter, setResultsFilter] = useState<IPatientInfoBasic[]>([]);
  const [profesional, setProfesional] = useState<string | undefined>('');
  const [userId, setUserId] = useState<string | undefined>('');
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenModalConfirmEmail, setOpenModalConfirmEmail] = useState(false);
  const [isNotData, setisNotData] = useState(true);
  const [selectedPatient, setSelectedPatient] = useState<IPatientInfoBasic>({});
  const date = getCurrentDate();

  const searchPatients = (): Promise<IPatientInfoBasic[] | undefined> => {
    try {
      return patientApi.getlistPatientsByDocNameSurname(dataFilter);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const confirmEmail = (id: number, email: string): Promise<string | undefined> => {
    try {
      const res = patientApi.updateEmailClinicRecord({ id, email });
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const sendEmailRequestClinicRecord = (params: any): Promise<string | undefined> => {
    try {
      const res = patientApi.sendEmailRequestClinicRecord(params);
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const { isLoading, isError, isFetching, refetch } = useQuery(['searchPatientByDocNameSurname'], () => searchPatients(), {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const filterData = (params: TFormFilterProfesionalPatirentValues) => {
    setResultsFilter([]);
    setProfesional(params.profesionalName);
    params.dateTo = date;
    setDataFilter(params);
  };

  const onConfirmEmail = async (data: any) => {
    const { emailClinicRecord } = data;
    await confirmEmail(selectedPatient?.id || 0, emailClinicRecord);
    setDataFilter({
      ...dataFilter,
      isProcess: dataFilter?.isProcess === undefined ? true : !dataFilter.isProcess,
    });
    setOpenModalConfirmEmail(false);
  };

  const onSendEmailRequest = async () => {
    const isVerify = selectedPatient.emailVerify && selectedPatient.emailVerify?.length > 0 ? true : false;
    const emailAccount = isVerify ? selectedPatient.emailVerify : selectedPatient.emailClinicRecord;

    const res = await sendEmailRequestClinicRecord({
      userId: userId,
      patientId: selectedPatient.id,
      identifiType: selectedPatient.identifiType,
      identifi: selectedPatient.identifi,
      profesionalName: profesional,
      emailAccount: emailAccount,
      appointmentDate: date,
      isVerify,
    });
    setOpenModal(false);
    if (!res) {
      const options: OptionsObject = {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      };
      enqueueSnackbar('No podemos realizar la solicitud en estos momentos, por favor solicitar soporte.!!', options);
    } else {
      const options: OptionsObject = {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      };
      enqueueSnackbar('Se registro la solicitud para el envió de la historia clínica al correo electrónico.!!', options);
      setDataFilter({
        ...dataFilter,
        isProcess: dataFilter?.isProcess === undefined ? true : !dataFilter.isProcess,
      });
    }
  };

  useEffect(() => {
    setUserId(getUserId());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (dataFilter !== undefined) {
        setisNotData(true);
        setResultsFilter([]);
        const res: any = await refetch();
        setResultsFilter(res.data);
        if (res.data.length > 0) setisNotData(false);
      }
    };

    fetchData();
  }, [dataFilter, refetch]);

  return (
    <div className="w-full mt-1 flex flex-col gap-2">
      <FilterByProfesionalPatient
        listProfesionals={listProfesionals}
        filterData={filterData}
      />

      {isLoading || isFetching ? (
        <LoadingIcon size={3} />
      ) : (
        <Cards
          items={resultsFilter}
          setSelectedPatient={setSelectedPatient}
          setOpenModal={setOpenModal}
          setOpenConfirmEmail={setOpenModalConfirmEmail}
        />
      )}

      {isError && (
        <div className="w-full flex justify-center items-center">
          <p className="text-red-400 text-lg sm:text-sm">Error al cargar los datos...!</p>
        </div>
      )}

      {isNotData && (
        <div className="w-full flex justify-center items-center">
          <p className="text-gray-400 text-lg sm:text-sm">No hay datos para mostrar...!</p>
        </div>
      )}

      <Modal
        open={isOpenModal}
        closeModal={setOpenModal}
        icon={<MdOutlineAttachEmail />}
        title={'Enviar historia clínica'}
        subtitle={'Atención Diaria'}
      >
        <SendEmailRequest
          patient={selectedPatient}
          profesional={profesional}
          date={date}
          onClick={onSendEmailRequest}
        />
      </Modal>

      <Modal
        open={isOpenModalConfirmEmail}
        closeModal={setOpenModalConfirmEmail}
        icon={<MdOutlineAttachEmail />}
        title={'Confirmar'}
        subtitle={'Correo Electrónico'}
      >
        <ConfirmEmail
          patient={selectedPatient}
          onClick={onConfirmEmail}
        />
      </Modal>
    </div>
  );
};
