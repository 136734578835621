import { MdOutlineAttachEmail } from 'react-icons/md';
import { SidebarMenu } from './SidebarMenu';
import { IPropsSidebarMenuItem } from '../../interfaces';
import { useAuth } from '../../auth/hooks/useAuth';

const menuItems: IPropsSidebarMenuItem[] = [
  {
    group: 'Línea de Frente',
    title: 'Enviar Historia Clínica',
    subtitle: 'Enviar la historia clínica del día de hoy',
    path: '/clinic-record-send-today',
    icon: <MdOutlineAttachEmail size={32} />,
  },
  // {
  //   group: 'Gestión Documental',
  //   title: 'Enviar Historia Clínica ',
  //   subtitle: 'Enviar la historia clínica por fecha',
  //   path: '/bidi/clinic-record/send-to-email/all',
  //   icon: <MdOutlineAttachEmail size={32} />,
  // },
  // {
  //   group: 'Informes',
  //   title: 'Macro-Informes',
  //   subtitle: 'Permite descargar en Excel la información',
  //   path: '/',
  //   icon: <MdOutlineAttachEmail size={32} />,
  // },
];

export const Sidebar = () => {
  const { getUser } = useAuth();
  const { role } = getUser();
  if (role === 'admin') {
    console.log('role', role);
  }
  return (
    <div className="sidebar fixed left-0 top-14 z-10 flex h-full w-14 flex-col border-none bg-slate-50 text-white transition-all duration-300 hover:w-64 md:w-64">
      <div className="flex flex-grow flex-col justify-between overflow-y-auto overflow-x-hidden">
        <div className="flex flex-col space-y-1 py-1">
          <div>
            <br />
          </div>
          <SidebarMenu menuItems={menuItems} />
        </div>
      </div>
    </div>
  );
};
