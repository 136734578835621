import { useEffect, useMemo, useState } from 'react';
import { IoCaretUp, IoCaretDown } from 'react-icons/io5';

import { SidebarMenuItem } from './SidebarMenuItem';
import { IPropsSidebarMenuItem } from '../../interfaces';

type props = {
  menuItems: IPropsSidebarMenuItem[];
};
export const SidebarMenu = ({ menuItems }: props): JSX.Element => {
  const groups: string[] = Array.from(new Set(menuItems.map((item) => item.group)));

  const [activeGroup, setActiveGroup] = useState('');
  const [activeItem, setActiveItem] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleResize = useMemo(() => {
    return () => {
      setIsSmallScreen(window.innerWidth < 775);
    };
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const toggleGroup = (group: string) => {
    setActiveGroup(activeGroup === group ? '' : group);
  };

  const isGroupActive = (group: string) => {
    return activeGroup === group;
  };

  const handleItemClick = (title: string) => {
    setActiveItem(title);
  };

  if (isSmallScreen) {
    return (
      <>
        {menuItems.map((item, index) => (
          <SidebarMenuItem
            key={index}
            item={item}
            activeItem={activeItem}
            onClick={handleItemClick}
          />
        ))}
      </>
    );
  } else {
    return (
      <>
        {groups.map((group) => {
          const groupItems = menuItems.filter((item) => item.group === group);

          return (
            <div
              key={group}
              className="mb-4"
            >
              <div
                className={`w-full cursor-pointer p-2 px-2 py-1 font-semibold text-bidiBlue-700 ${
                  isGroupActive(group) ? 'bg-gray-200 text-bidiBlue-500' : 'text-bidiBlue-700'
                } mb-2 rounded-md hover:bg-gray-200 hover:text-bidiBlue-500`}
                onClick={() => toggleGroup(group)}
              >
                <div className="flex items-center justify-between">
                  <span>{group}</span>
                  {isGroupActive(group) ? <IoCaretUp /> : <IoCaretDown />}
                </div>
              </div>
              {isGroupActive(group) && (
                <div className="pl-4">
                  {groupItems.map((item, index) => (
                    <SidebarMenuItem
                      key={index}
                      item={item}
                      activeItem={activeItem}
                      onClick={handleItemClick}
                    />
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  }
};
