import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export const validateCookieUser = () => {
  const jwt_user = Cookies.get('bidiTK') || '';
  const decode = jwt_decode(jwt_user);
  console.log(decode);
  if (!decode) return false;
  return true;
};

export const deleteCookieUser = () => {
  Cookies.remove('_token');
  Cookies.remove('_name');
  Cookies.remove('_role');
};
