interface props {
  type: string;
  id: string;
  label: string;
  placeholder?: string;
  register: any;
  errors: any;
}
export const TextField = ({ type, id, label, placeholder, register, errors }: props) => {
  const sytle =
    'form-control wfull px-3 py-1.5 text-gray-700 rounded border border-solid border-gray-300 focus:borde-blue-500 focus:outline-none';

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <label className="mb-2 block text-sm font-bold text-bidiBlue-1000">{label}</label>
        {type === 'textarea' ? (
          <textarea
            rows={3}
            placeholder={placeholder}
            className={sytle}
            {...register(id)}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            className={sytle}
            {...register(id)}
          />
        )}
        {errors && <p className="text-sm italic text-red-500">{errors[id]?.message}</p>}
      </div>
    </div>
  );
};
