type PropsTooltip = {
  conditional: boolean;
  text: string;
  textF?: string;
};
export const Tooltip = (props: PropsTooltip) => {
  const { conditional, text, textF } = props;
  return (
    <span className="group-hover:opacity-100 transition-opacity bg-gray-800 p-2 text-xs text-gray-100 rounded-md absolute translate-x-60 -translate-y-16 opacity-0 m-4 mx-auto">
      {conditional ? text : textF}
    </span>
  );
};
