import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { store } from '../store';
import { AuthProvider } from './AuthProvider';

interface props {
  children: React.ReactNode;
}
export const MainProvider = ({ children }: props) => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <AuthProvider>{children}</AuthProvider>
      </SnackbarProvider>
    </Provider>
  );
};
