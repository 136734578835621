import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/hooks/useAuth';

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const { getIsAuthenticated } = useAuth();
  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', {
        replace: true,
      });
    }
  }, [isAuthenticated, navigate]);

  return <>{children}</>;
};
