import { GiConfirmed } from 'react-icons/gi';
import { IPatientInfoBasic } from '../../../../../../interfaces';
import { EmailField } from '../../../../../../components';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const schema = z.object({
  emailClinicRecord: z.string().nonempty('Correo requerido').email('Formato del correo no es valido'),
});

interface props {
  patient: IPatientInfoBasic;
  onClick: any;
}
export const ConfirmEmail = ({ patient, onClick }: props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      emailClinicRecord: '',
    },
    resolver: zodResolver(schema),
  });
  const fullName = `${patient.identifiType} ${patient.identifi} - ${patient.firstName} ${patient.secondName} ${patient.firstSurname} ${patient.secondSurname}`;
  const textColorClass = patient.emailVerify && patient.emailVerify?.length > 0 ? 'text-green-700' : 'text-red-700';
  const isVerify = patient.emailVerify && patient.emailVerify?.length > 0 ? true : false;

  return (
    <div className="w-full">
      <div className="p-2 text-justify">
        <span className="lg:txt-lg text-sm text-bidiBlue-1000 sm:text-xs md:text-sm">
          ¿Esta seguro que desea confirmar el correo electrónico de {fullName}?
        </span>
      </div>
      <div className="p-2 flex justify-center gap-10">
        {isVerify && <p className={`text-sm ${textColorClass}`}>Correo: {patient.emailVerify?.toLowerCase()}</p>}
        {!isVerify && <p className={`text-sm ${textColorClass}`}>Correo: {patient.email?.toLowerCase()}</p>}
        {patient.updatedEmailAnnar && (
          <p className="text-sm text-purple-950">Correo usado 🔬: {patient.updatedEmailAnnar?.toLowerCase()}</p>
        )}
      </div>
      <form
        className="flex flex-col"
        onSubmit={handleSubmit(onClick)}
      >
        <div className="flex justify-center p-2">
          <div className="mb-5 w-2/3">
            <EmailField
              id={'emailClinicRecord'}
              label={'Digite Correo Electrónico:'}
              register={register}
              errors={errors}
            />
          </div>
        </div>

        <div className="flex w-full justify-end rounded-b-lg border-gray-200 bg-bidiBlue-500 shadow">
          <button
            type="submit"
            className="hover:bg-hsl-208-60-39-40 inline-flex items-center rounded-lg rounded-tr-none bg-bidiBlue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-bidiBlue-400 "
          >
            <GiConfirmed
              size={20}
              className="mr-2"
            />
            Confirmar
          </button>
        </div>
      </form>
    </div>
  );
};
