import axios from 'axios';

import { IPatientInfoBasic } from '../../interfaces';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const patientApi = axios.create({
  baseURL: `${apiUrl}/patients`,
  headers: {},
});

export const getlistPatientsByDocNameSurname = async (params: any): Promise<IPatientInfoBasic[] | undefined> => {
  try {
    const { data } = await patientApi.post<IPatientInfoBasic[]>('/listPatientsByDocNameSurname', { params });
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const updatePatientEmailAnnar = async (): Promise<string | undefined> => {
  try {
    const { data } = await patientApi.post<string>('/updateEmailAnnar');
    return data;
  } catch (error) {
    console.log(error);
    return 'FAIL';
  }
};
export const updateEmailClinicRecord = async (params: any): Promise<string | undefined> => {
  try {
    const { data } = await patientApi.post<string>('/updateEmailClinicRecord', { params });
    return data;
  } catch (error) {
    console.log(error);
    return 'FAIL';
  }
};
export const sendEmailRequestClinicRecord = async (params: any): Promise<string | undefined> => {
  try {
    const { data } = await patientApi.post<string>('/sendEmailRequestClinicRecord', { params });
    return data;
  } catch (error) {
    console.log(error);
    return 'FAIL';
  }
};

export default {
  getlistPatientsByDocNameSurname,
  updatePatientEmailAnnar,
  updateEmailClinicRecord,
  sendEmailRequestClinicRecord,
};
