import { useEffect } from 'react';
import { MdOutlineAttachEmail } from 'react-icons/md';

import { AppLayout } from '../../../../layouts';
import { BreadCrumb, LoadingIcon } from '../../../../../components';
import { hooksProfesionals, hooksPatients } from '../../../../../hooks';
import { patientApi } from '../../../../../api';

import { MainSection } from './components/MainSection';

const updateEmailAnnar = (): Promise<string | undefined> => {
  try {
    return patientApi.updatePatientEmailAnnar();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export function ClinicalRecordSendEmailTodayPage() {
  const listProfesionalsQuery = hooksProfesionals.useProfesionalsListSchedule();

  useEffect(() => {
    updateEmailAnnar();
  }, []);

  return (
    <AppLayout>
      <div className="mt-1 flex h-5/6 items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center">
          <BreadCrumb
            icon={<MdOutlineAttachEmail size={28} />}
            text={'Enviar historia clínica / Atención Diaria'}
          />
          {listProfesionalsQuery.isLoading ? <LoadingIcon /> : <MainSection listProfesionals={listProfesionalsQuery.data || []} />}
        </div>
      </div>
    </AppLayout>
  );
}
